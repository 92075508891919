import { ApiEndPoints } from '@/api/Endpoints';
import { API } from '@/api/API';
import{useCapabilities}from '@/utils/Capabilities'


export const show = async (id) =>{
    try {
        const { isPublisher, isConcessionaire} = useCapabilities()
        const usePublisherController = isPublisher()||isConcessionaire()
        const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/campaign' : ApiEndPoints.CAMPAIGNS
        const {data} = await API.get(endpoint+'/'+id)
        //const {data} = await API.get(ApiEndPoints.CAMPAIGNS+'/'+id)
        return data 
    } catch (error) {
        return error
    }
    
}

export const apiCampaignFunction = {
    show
} 