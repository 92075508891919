<template>
    <form 
        :class="campaign.active ?'campaign-edit flex-container flex-col layout-content w100 h100 active-campaign' : 'campaign-edit flex-container flex-col layout-content w100  h100' "  
        @submit.prevent 
        ref="form"
        :key="campaign.id+'-'+ keyCounter"
        id="campaign-form"
    >
        <div class="layout-content-header flex-container p24 no-grow">
            <h1 class="nomargin bebas mr16">{{campaign.title ? campaign.title : 'nome campagna'}}</h1>
            <div class="header-buttons-container flex-container rel">
                <div class="active-badge mr8" v-if="campaign.active">
                    Attiva
                </div>
            </div>
            
        </div>
        <div 
            class="flex-container flex-col scrollable-content pl24 pr24" 
            id="campaign-card-content"
        >
            <div class="flex-container flex-col no-grow">
                <div class="flex-container mb24 no-grow flex-start-align">
                    <div class="flex-container flex-col h100 pr8">
                        <text-input
                            :label="'Nome'"
                            :value="campaign.title"
                            :editing="editing"
                            :placeholder="'inserisci nome campagna'"
                            :name="'title'"
                        >
                        </text-input>
                        <select-input
                            :label="'Piattaforma'"
                            :value="campaign.type"
                            :editing="editing"
                            :placeholder="'scegli la piattaforma'"
                            :options="['Facebook', 'Instagram', 'Youtube']"
                            :name="'type'"
                        ></select-input>
                        
                        <linked-calendars
                            :startDate="dates.startDate"
                            :endDate="dates.endDate"
                            :editing="editing"
                            @data-change="onDatesChanged"
                            :keysuffix="keyCounter"
                        ></linked-calendars>
                        <div class="flex-container mt8">
                             <div class="flex-container flex-self-align-start flex-col">
                                <div class="flex-container flex-col">
                                    <div class="flex-container mb8">
                                        
                                        <price-input
                                            :label="'budget'"
                                            :value="campaign.budget"
                                            :editing="editing"
                                            :placeholder="'modifica'"
                                            :name="'budget'"
                                            :className="'flex-container flex-col pr8'"
                                        ></price-input>
                                        
                                    </div>
                                </div> 
                            </div>
                        </div>

                    </div>

                    <div class="flex-container flex-col h100 pl8">
                        <select-input
                            :label="'Media'"
                            :value="campaign.mediaType"
                            :editing="editing"
                            ref="mediaType"
                            :name="'mediaType'"
                            :placeholder="'scegli il medium'"
                            :options="['Image', 'Video']"
                        ></select-input>
                        <text-input
                            :label="'url media'"
                            :value="campaign.mediaLink"
                            :editing="editing"
                            :placeholder="'url video/immagine'"
                            :name="'mediaLink'"
                        >
                        </text-input>
                        <MediaPreview
                            :mediaType="campaign.mediaType"
                            :mediaLink="campaign.mediaLink"
                        ></MediaPreview>
                        
                        <text-area-input
                            :label="'descrizione'"
                            :value="campaign.description"
                            :editing="editing"
                            :placeholder="'inserisci una breve descrizione'"
                            :name="'description'"
                            :className="'flex-container flex-col'"
                        ></text-area-input>
                    </div>
                </div>
            </div>
            
            <CampaignSocials
                :editing="editing"  
                :campaign="campaign"
                @data-changed="onCampaignSocialsChanged"
                :keysuffix="keyCounter"
                :key="'social-campaign-'+keyCounter"
            ></CampaignSocials>
            
        </div>
        <form-footer
            @edit-enable="onEditEnable"
            @edit-disable="onEditDisable"
            @submit-form="savePressed"
            @delete-press="onDeleteClick"
            :editing="editing"
        ></form-footer>
        
    </form>
    <confirm-panel
        v-if="confirmVisible"
        :title="'Elimina ' + campaign.title"
        :message="'sei sicuro di voler eliminare ' + campaign.title + '?'"
        @allow-confirm="onDeleteConfirm"
        @deny-confirm="confirmVisible = false"
    >
    </confirm-panel>
    <FacebookResetToken
        v-if="resettingToken"
        @close="resettingToken = false"
    ></FacebookResetToken>
</template>
<script>
import {ref, computed, reactive } from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {ApiEndPoints} from '@/api/Endpoints'
//import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import CampaignSocials from '@/components/campaigns/elements/CampaignSocials'
import MediaPreview from '@/components/campaigns/elements/MediaPreview'
import TextInput from '@/components/formelements/TextInput'
import PriceInput from '@/components/formelements/PriceInput'
import TextAreaInput from '@/components/formelements/TextAreaInput'
import SelectInput from '@/components/formelements/SelectInput'
import FormFooter from '@/components/formelements/FormFooter'
import ConfirmPanel from '@/components/ConfirmPanel'
import LinkedCalendars from '@/components/LinkedCalendars'
import FacebookResetToken from '@/components/FacebookResetToken'
import {USER_ROLE_ADVERTISER} from '@/utils/RolesAndPolicies'
import {formatNumber} from '@/utils/NumberFunctions'
import {timeStampToFormattedDate} from '@/utils/DateTimeUtils'
import {arraysContainSame} from '@/utils/ArrayFunctions'
import {
    acceptSocial, 
    unacceptSocial,  
    saveCampaign
} from '@/utils/CampaignFunctions'


export default {
    components:{
        TextInput,
        TextAreaInput,
        PriceInput,
        SelectInput,
        MediaPreview,
        CampaignSocials,
        ConfirmPanel,
        LinkedCalendars,
        FormFooter,
        FacebookResetToken,
        //AjaxSearchSelect
    },
    props:{
        selCampaign:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props){
       
        const store = useStore()
        const router = useRouter()
        let savedSocialsIds = props.selCampaign?.socialCampaigns?.map(s=>s.socialId) || []
        const loading = ref(false)
        
        let campaign = reactive(props.selCampaign)
        
        const keyCounter = ref(0)
        const resettingToken = ref(false)
        
        const originalTags = props.selCampaign.campaignTags.map(t =>t)
        const originalTagsIds = props.selCampaign.campaignTags.map(t =>t.tagId)

        // eslint-disable-next-line vue/no-setup-props-destructure
        const originalPosts = props.selCampaign.postCampaigns || []
        const editing =ref(false)
        const newName = ref(null)
        const form = ref(null)
        const allTags = computed(()=>store.getters['tags/tags'])
        const advertisers = computed(()=>store.getters['user/users']
                                .filter(user => user.roles[0] === USER_ROLE_ADVERTISER))

       
        const onAdvertiserChange = (advertiser)=>{
            campaign.advertiserUserId = advertisers.value.find(ad => ad.userName === advertiser).id
        }
        
        const confirmVisible = ref(false)
        
        const onDeleteClick = async()=>{
            confirmVisible.value=true
        }
        const onDeleteConfirm  = async()=>{
            
            try {
                await store.dispatch('campaigns/remove', props.selCampaign.id)
                await store.dispatch('messages/message', {
                    title:'campagna eliminata',
                    message:'hai cancellato  la campagna ' + props.selCampaign.title
                })
                router.push('/campaigns')

            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella eliminazione della campagna',
                    message:error.message
                })
            }
        }

        const onSocialsChanged =(socials)=>{
            campaign.socialCampaigns = socials
        }
         
        const onCampaignSocialsChanged =(data)=>{ 
            campaign.campaignTags = data.tags.map(tag => {
                return {
                    tagId:tag.id, 
                    campaignId:campaign.id,
                    tag:tag
                }
            })
            //campaign.socialCampaigns = data.socialCampaigns
        }
       /*  const onTagsSelect = (tag)=>{
            campaign.campaignTags.push({
                tagId:tag.id, 
                campaignId:campaign.id,
                tag:tag
            })
            
        } */
        /* const onTagsDeSelect =(tag)=>{
            campaign.campaignTags = campaign.campaignTags
                                        .filter(t=> t.tagId !== tag.id )
        } */
        
        

        const onEditDisable = async()=>{
           
            const singleCampaign = await store.dispatch('campaigns/getSingle', props.selCampaign.id)
            Object.keys(campaign).forEach(key =>{
                campaign[key] = singleCampaign[key]
            })
            dates.startDate = singleCampaign.start
            dates.endDate = singleCampaign.end
            campaign.campaignTags = singleCampaign.campaignTags  
            campaign.socialCampaigns = singleCampaign.socialCampaigns  
            editing.value = false
            keyCounter.value--

        }

        const onEditEnable = ()=>{
            editing.value = true
            keyCounter.value++
            dates.startDate = campaign.start
            dates.endDate = campaign.end
            campaign.campaignTags = originalTags            
            campaign.postCampaigns = originalPosts            
        }

        const savePressed = async()=>{
            const currentTagIds =campaign.campaignTags.map(t=>t.tagId)
            if(!arraysContainSame(currentTagIds, originalTagsIds)){
                await syncTags()
            }  
            
            const currentSocialIds = campaign.socialCampaigns?.map(s=>s.socialId) || []
            
            if(!arraysContainSame(currentSocialIds, savedSocialsIds)){
                loading.value = true
                await syncSocials()
                loading.value = false
                
            } 

            await save()
            keyCounter.value++
        }
        

        const syncTags = async()=>{
            try {   
                await store.dispatch('campaigns/syncTags', {
                    id:campaign.id,
                    tags:campaign.campaignTags
                }) 
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella modifica dei target', 
                    message:error.message 
                })
            }
        }
         const syncSocials= async()=>{
            
            let i=0
            for(i=0; i < campaign.socialCampaigns.length; i++) {
                const social = campaign.socialCampaigns[i]
                if(!savedSocialsIds.includes(social.socialId)){
                    await addCampaignSocialRelation(social)
                }  
            } 
            for(i=0; i < savedSocialsIds.length; i++) {
                
                const current = campaign.socialCampaigns.map(s=>s.socialId)
                
                if(!current.includes(savedSocialsIds[i])){
                    await removeCampaignSocialRelation(savedSocialsIds[i]) 
                    //campaigns.socialCampaigns = campaigns?.socialCampaigns?.filter(s => s.socialId !==savedSocialsIds[i] ) || []
                }
            }
            
            savedSocialsIds = campaign.socialCampaigns.map(s=>s.socialId)
        }
        const addCampaignSocialRelation = async(social)=>{

            try {
                loading.value = true

                const response = await store.dispatch('campaigns/addSocial',{
                    campaignId:campaign.id,
                    socialId:social.socialId
                })
                savedSocialsIds.push(response.socialId)
                loading.value = false
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:`errore durante l'associazione `, 
                    message:error.message 
                })
                loading.value = false
            }
            

        }

        const removeCampaignSocialRelation = async(sid)=>{
            try {
                loading.value = true
                await store.dispatch('campaigns/removeSocial',{
                    campaignId:campaign.id,
                    socialId:sid
                })
                loading.value = true
            } catch (error) {
               await store.dispatch('messages/message', {
                    title:`errore durante la rimozione `, 
                    message:error.message 
                })
                loading.value = false 
            } 
        }
        
       
        const collectData = ()=>{
            const formData = new FormData(form.value)
            //merge dell'oggetto campaign con i dati modificati /inseriti nel form
            for (let key of formData.keys()) {
                campaign[key] = formData.get(key)
            }
            //copio l'oggetto campaign
            const data = {...campaign}
            let startTimezoneOffset = new Date(dates.startDate).getTimezoneOffset()
            let endTimezoneOffset = new Date(dates.endDate).getTimezoneOffset()
            const newStart = new Date(dates.startDate).setHours(0 -(startTimezoneOffset/60),0,0)
            const newEnd = new Date(dates.endDate).setHours(23- (endTimezoneOffset/60),59,59)
            data.start = new Date(newStart).toISOString()
            data.end = new Date(newEnd).toISOString()
            data.advertiserUserId
            //elimino dall'oggetto i campi relativi alle relazioni
            delete data.socialCampaigns
            delete data.campaignTags
            delete data.postCampaigns
            delete data.posts
            
            return data
        }

        const save = async()=>{
            loading.value = true
            const data = collectData()
            await saveCampaign(data, campaign)
            loading.value = false
            await updateSingleData()
            keyCounter.value++ 
        }

        const updateSingleData = async() => {
            const singleCampaign = await store.dispatch('campaigns/getSingle', props.selCampaign.id)
            Object.keys(campaign).forEach(key =>{
                campaign[key] = singleCampaign[key]
            })
            campaign.campaignTags = singleCampaign.campaignTags  
            campaign.socialCampaigns = singleCampaign.socialCampaigns  
            loading.value = false
        }

        ///calendar ctrl
        const dates = reactive({
            startDate:campaign.start,
            endDate:campaign.end,
        })

        const onDatesChanged = ({startDate, endDate})=>{
            dates.startDate = startDate
            dates.endDate = endDate
        }

        

        return {
            form,
            newName,
            editing,
            unacceptSocial,
            acceptSocial,
            allTags,
            onDeleteClick,
            onDeleteConfirm,
            confirmVisible,
            savePressed,
            campaign,
            onCampaignSocialsChanged,
            onSocialsChanged,
            onDatesChanged,
            dates,
            onEditDisable,
            onEditEnable,
            keyCounter,
            arraysContainSame,
            resettingToken,
            advertisers,
            onAdvertiserChange,
            timeStampToFormattedDate,
            loading,
            formatNumber,
            ApiEndPoints,
            //onTagsSelect,
            //onTagsDeSelect
        }
    }
}
</script>
<style lang="postcss">
    .mediapreview{
        & img{
            max-width: 100%;
            height:250px;
            object-fit:contain;
        }
        & .videoplayer{
            max-width: 100%;
            height:250px;
        }
    }
    #campaign-form{
        height: calc(100vh - 148px);
    }
    
    .header-buttons-container{
       justify-content: flex-end;
    }
    .last-update {
        bottom: -20px;
    }
</style>


