<template>
    <div class="flex-container flex-col w100">
        <PriceTypes
            :editing="editing"
            :priceType="data.socialCampaigns[0].priceType"
            :price="data.socialCampaigns[0].price"
            :organicCoveragePrice="data.socialCampaigns[0].organicCoveragePrice"
            :sponsoredCoveragePrice="data.socialCampaigns[0].sponsoredCoveragePrice"
            :className = "'flex-container flex-start-align pb8 pt8 pl8'"
            @input="onPriceInput"
            @change="onPriceType"
        ></PriceTypes>
       
        <p class="pt0 nomargin pb8 pl8">
            Modifica il prezzo di <strong>{{social.name}}</strong> 
            per questa <strong>Campagna</strong>
        </p>
        <InflooButton
            :enabled="modified"
            @click="updatePrices"
            :className="'button primary ml8 mr8 '"
        >
            salva modifiche ai prezzi
        </InflooButton>
    </div>
</template>
<script>
//import PriceInput from '@/components/formelements/PriceInput'
import InflooButton from '@/components/buttons/InflooButton'
import PriceTypes from '@/components/formelements/PriceTypes'
import { reactive, ref, inject } from 'vue'
import {useStore} from 'vuex'
export default {
    /* emits:['price-input'], */
    props:{
        social:{
            type:Object,
            default:()=>{}
        },
        editing:{
            type:Boolean,
            default:false
        }
    
    },
    components:{
        //PriceInput,
        InflooButton,
        PriceTypes
    },
    setup(props/* , context */){
        const store = useStore()
        const currentCampaign = inject('currentCampaign')
        const data = reactive(props.social)
        const currentSocialCampaign = props.social.socialCampaigns.find(c => c.campaignId ===currentCampaign.id)
        
        if(currentSocialCampaign?.price){
            data.price = currentSocialCampaign.price
        }
        if(currentSocialCampaign?.organicCoveragePrice){
            data.organicCoveragePrice = currentSocialCampaign.organicCoveragePrice
        }
        if(currentSocialCampaign?.sponsoredCoveragePrice){
            data.sponsoredCoveragePrice = currentSocialCampaign.sponsoredCoveragePrice
        }
        
        const modified = ref(false)
        
        const onPriceInput = ({text, name}) =>{
            modified.value = true
            data[name]= parseFloat(text)
        }
        const onPriceType = (type) =>{
            modified.value = true
            data.priceType = type
        }
        const updatePrices = async () => {
            try {
                store.dispatch('campaigns/updateSocialPrices',{
                    socialId:data.socialId,
                    campaignId:currentCampaign.id,
                    data:{
                        priceType: data.priceType,
                        price:data.price,
                        organicCoveragePrice:data.organicCoveragePrice,
                        sponsoredCoveragePrice:data.sponsoredCoveragePrice
                    }
                })
            } catch (error) {
              
                await store.dispatch('messages/message', {
                    title:"errore nella modifica dei prezzi",
                    message:error.message 
                })  
            }
        }
        return{
            data,
            onPriceInput,
            onPriceType,
            modified,
            updatePrices
        }
    }
}
</script>