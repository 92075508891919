<template>
    <div class="flex-grow-1 rel">
        <div class="panel-header tab-header pl24 pr24">
            <div class="mini-logo "></div>
            <ul 
                class="flex-container layout-list nopadding nomargin flex-end-align h100"
            >
                <li class="layout-list-item">
                    <a 
                        href="#" 
                        :class="selected === 'campaign' 
                            ? 'layout-list-item-btn pl16 pr24 selected' 
                            : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('campaign')"
                    >
                        <span class="pl8">Campagna</span>
                    </a>
                </li>
                <li 
                    v-if="(isAdmin() || isPublisher() || isConcessionaire()) && campaign.type === 'Facebook'"
                    class="layout-list-item"
                >
                    <a 
                        href="#" 
                        :class="selected === 'facebook' 
                            ? 'layout-list-item-btn pl16 pr24 selected' 
                            : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('facebook') "
                    >
                        <i class="lni lni-facebook-original"></i>
                        <span class="pl8">pagine</span>
                    </a> 
                </li>
                <li 
                    v-else-if="(isAdmin() || isPublisher() || isConcessionaire()) && campaign.type === 'Instagram'"
                    class="layout-list-item"
                >
                    <a 
                        href="#" 
                        :class="selected === 'instagram' 
                            ? 'layout-list-item-btn pl16 pr24 selected' 
                            : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('instagram') "
                    >
                        <i class="lni lni-instagram-original"></i>
                        <span class="pl8">pagine</span>
                    </a> 
                </li>
                
                <li class="layout-list-item">
                    <a 
                        href="#" 
                        :class="selected === 'analytics' 
                            ? 'layout-list-item-btn pl16 pr24 selected' 
                            : 'layout-list-item-btn pl16 pr24'" 
                        @click.prevent="onTabSelected('analytics')"
                    >
                        <i class="lni lni-investment"></i>
                        <span class="pl8">Analitiche</span>
                    </a> 
                </li>
            </ul>
        </div>
        <AdminCampaignCard
            v-if="selected==='campaign' && isAdmin()" 
            :selCampaign="campaign" 
            :key="campaign.id + '-admin'"
        ></AdminCampaignCard>
        <AdvertiserCampaignCard
            v-else-if="selected==='campaign' && isAdvertiser()" 
            :selCampaign="campaign" 
            :key="campaign.id + '-advertiser'"
        ></AdvertiserCampaignCard>
        <PublisherCampaignCard
            v-else-if="selected==='campaign' && (isPublisher() || isConcessionaire())" 
            :selCampaign="campaign" 
            :key="campaign.id + '-publisher'"
        ></PublisherCampaignCard>
        <facebook-pages 
            v-else-if="selected==='facebook'" 
            :key="campaign.id+ '-'+selected" 
            :campaign="campaign"
        ></facebook-pages>
        <InstagramPages 
            v-else-if="selected==='instagram'" 
            :key="campaign.id+ '--'+selected" 
            :campaign="campaign"
        ></InstagramPages>
        <CampaignAnalytics 
            v-else-if="selected==='analytics'" 
            :key="campaign.id + '-analytics'" 
            :campaign="campaign"
        >
        </CampaignAnalytics>
    </div>    
</template>
<script>
import {ref,computed, provide} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import AdminCampaignCard from '@/components/campaigns/cards/AdminCampaignCard'
import AdvertiserCampaignCard from '@/components/campaigns/cards/AdvertiserCampaignCard'
import PublisherCampaignCard from '@/components/campaigns/cards/PublisherCampaignCard'
import FacebookPages from '@/components/campaigns/facebook/FacebookPages.vue'
import InstagramPages from '@/components/campaigns/instagram/InstagramPages.vue'
import CampaignAnalytics from '@/components/campaigns/analytics/CampaignAnalytics.vue'
import {useCapabilities} from '@/utils/Capabilities'
export default {
    props:{
        selectedTab:{
            type:String,
            default:'campaign'
        },
        campaign:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        AdminCampaignCard,
        AdvertiserCampaignCard,
        PublisherCampaignCard,
        FacebookPages,
        InstagramPages,
        CampaignAnalytics
    },
    setup(props){
        provide('currentCampaign', props.campaign)
        
        
        
        const selected = ref(props.selectedTab)
        const router = useRouter()
        const route = useRoute()
        const {isAdmin, isAdvertiser, isPublisher, isConcessionaire} = useCapabilities()

       
        
        const linkedPosts = computed(()=>{
            return props.campaign?.posts || []
        }) 
       
        const onTabSelected = (tab) => {
            
            selected.value = tab
            router.push({path: route.path, query: { tab: tab }})
        }

       
        return{
            selected,
            linkedPosts,
            onTabSelected,
            isAdmin,
            isAdvertiser,
            isPublisher,
            isConcessionaire
        }
    }
}
</script>
