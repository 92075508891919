<template>
    <div class="flex-container flex-col w100">
        <div v-if="hasPosts" :class="`post-container flex-container flex-col ${hasPublishDates ? '' : 'mb16'}`">
            <slot name="posts"></slot>
        </div>
        <div v-if="hasPublishDates" class="publishdates-container flex-container flex-col mb16">
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow mt8">
                <p class="bebas nomargin pl8">In attesa di pubblicazione</p>
            </div>
            <slot name="publishdates"></slot>
        </div>   
    </div>
</template>
<script>
import {useCapabilities} from '@/utils/Capabilities'
export default {
    props:{
        hasPosts:{
            type:Boolean,
            default:true
        },
        hasPublishDates:{
            type:Boolean,
            default:false
        }
    },
    setup(){
        const {isAdmin} = useCapabilities()
        return {
            isAdmin
        }
    }
}
</script>
<style scoped>
.publishdates-container{
    border-top: solid 1px #2cbeff;
}
</style>