<template>
    <li :class="className">
        <a 
            @click="onSelect"
            class=" flex-container pl8"
        >
            <div class="layout-list-item-title nomargin mb8 mt8">{{social.social.name ? social.social.name : 'Nome Social'}} </div>

            
            <div v-if="editing && canManageSocials" class="flex-container no-grow">
                <a
                    @click.prevent="()=>acceptSocialPressed(social)" 
                    v-if="showAcceptBtn"
                    class="confirm f24 flex-center-align flex-center-justify flex-container"
                >
                    <i class="lni lni-checkmark-circle"></i>
                </a>
                <a
                    @click.prevent="() => unacceptSocialPressed(social)" 
                    v-else-if="showDenyBtn"
                    class="deny remove-social-from-campaign f24 flex-center-align flex-center-justify flex-container"
                >
                    <i class="lni lni-ban"></i>
                </a>
                <a
                    @click.prevent="() => authorizeSocialPressed(social)" 
                    v-if="showAuthorizeBtn"
                    class="confirm f24 flex-center-align flex-center-justify flex-container"
                >
                    <i class="lni lni-thumbs-up"></i>
                </a>
                 <a
                    @click.prevent="() => deAuthorizeSocialPressed(social)" 
                    v-else-if="showDeAuthorizeBtn"
                    class="deny remove-social-from-campaign f24 flex-center-align flex-center-justify flex-container"
                >
                    <i class="lni lni-thumbs-down"></i>
                </a>
                
                <a 
                    v-if="showRemoveButton"
                    @click.prevent="()=>removeSocialFromCampaign(social)" 
                    class="deny remove-social-from-campaign f24 flex-center-align flex-center-justify flex-container"
                >
                    <i class="lni lni-close"></i>
                </a>
            </div>
        </a>
    </li>
</template>
<script>

import {computed, inject, ref } from 'vue'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
} from '@/utils/RolesAndPolicies'
import {useCapabilities} from '@/utils/Capabilities'

export default {
    emits:[ 
        'withdraw-campaign', 
        'add-to-campaign', 
        'remove-social-from-campaign',
        'authorize-social', 
        'deauthorize-social',
        'accept-social',
        'unaccept-social'
    ],
    props:{
        social:{
            type:Object,
            default:()=>{}
        },
        mode:{
            type:String,
            default:'linked'
        },
        editing:{
            type:Boolean,
            default:false
        },
    },
    setup(props,context){
        const me = inject('me')
        const {canManageSocials,  isPublisher, isConcessionaire,  isAdmin, isAdvertiser} = useCapabilities()
        
        const roleShowActionsButtons = canManageSocials  && (!isPublisher() && !isConcessionaire()) 

        const authorized = ref( props.social.authorized)
        const accepted = ref(props.social.accepted)
        const verified = ref(props.social.social.verified)
        
        const className = computed(()=> {     
            let cls = 'campaign-social layout-list-item'

            if(!authorized.value && authorized.value !== undefined){
                cls += ' to-auth'
            }
            
            if(accepted.value === 'Pending' ){
                cls += ' pending' 
            }
            if(accepted.value === 'Declined' ){
                cls += ' declined' 
            }
            
            if( accepted.value === 'Accepted' && authorized.value === true &&  verified.value){
                cls = 'campaign-social layout-list-item all-good'
            }
            
            return cls
            
        })

        
        const showAcceptBtn = computed(()=> {
           return !isAdvertiser() && roleShowActionsButtons && (accepted.value === 'Pending' || accepted.value === 'Declined')
        }) 


        const showDenyBtn = computed(()=> {
           return  !isAdvertiser() && roleShowActionsButtons && accepted.value === 'Accepted'
        }) 
        const showAuthorizeBtn = computed(()=> {
            
            return  !authorized.value 
                && roleShowActionsButtons 
                && (accepted.value !== undefined && accepted.value !== 'Candidate')
                && isAdmin()
               
                    
        })  
        const showDeAuthorizeBtn = computed(()=> {
           
            return  authorized.value && roleShowActionsButtons && !isAdvertiser()
                    
        }) 

        const showRemoveButton = computed(()=>{
           return isAdmin() || ((accepted.value === 'Pending' || accepted.value === 'Candidate' || !accepted.value) && !authorized.value)
        })
        
        const withdrawCampaign = (social)=>{
            context.emit('withdraw-campaign', social)
        }
        
        const removeSocialFromCampaign = (social)=>{
            context.emit('remove-social-from-campaign', social)
        }

        const acceptSocialPressed = (social) => {
            accepted.value = 'Accepted'
            context.emit('accept-social', social)
        }
        const unacceptSocialPressed = (social) => {
            accepted.value = 'Declined'
            context.emit('unaccept-social', social)
        }
        const authorizeSocialPressed = (social) => {
            authorized.value = true
            context.emit('authorize-social', social)
        }
        const deAuthorizeSocialPressed = (social) => {
            authorized.value = false
            context.emit('deauthorize-social', social)
        }


        return{
            me,
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            className,
            withdrawCampaign,
            removeSocialFromCampaign,
            authorizeSocialPressed,
            deAuthorizeSocialPressed,
            acceptSocialPressed,
            unacceptSocialPressed,
            canManageSocials,
            showAcceptBtn,
            showDenyBtn,
            showAuthorizeBtn,
            showDeAuthorizeBtn,
            showRemoveButton,
            accepted,
            authorized,
            isAdvertiser
        }
    }
    
}
</script>

<style lang="postcss">

.campaign-social{
    &.pending {
        background-color: lightpink;
        & .layout-list-item-title {
            color: white;
        }
    }
    &.to-auth {
        background-color: rgb(240, 150, 15);
    }
    &.declined {
        background-color:lightpink;
    }
    &.to-auth.pending{
        background-color: rgb(240, 67, 15);
    }
    &.declined {
        background-color:rgb(198, 100, 240);
        & .layout-list-item-title {
            color: white;
        }
    }
    &.to-auth.declined {
        background-color:  rgb(240, 67, 15);
    }
    &.all-good {
        background-color: lightgreen;
    }
    & a.confirm {
        text-decoration: none;
        color: #ffffff;
        background-color: #2cbeff;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
        border: none;
        width: 50px;

        &:hover{
            background-color: #2f3a3f;
            color:#fff;
        }
    }
    & a.deny {
        background-color: #2f3a3f;
        color: #F2F2F2;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
        border: none;
        width: 40px;
        &:hover{
            background-color: #2cbeff;
            color:#fff;
        }
    }
    
}
</style>

