<template>
    <li :class="className + ' accordion-social-element mb16 p16'">
            <div class="flex-container flex-space-between-justyfy w100 flex-center-align">
                <h3 class="bebas pr16 nomargin">{{element.social.name}}</h3>
                <h4
                    v-if="!isAdvertiser()"
                >
                    costo {{formatNumber(element.cost)}} €
                </h4>
                <p 
                    v-if="publisher"
                    class="pl8 layout-list-item-subtitle nomargin f12"
                >
                    <span><i>{{publisher.name}}</i></span>
                    <span
                        v-if="publisher.concessionaire" 
                        class="pl8 black">{{publisher.concessionaire.name}}
                    </span>
                </p>  
                  
                <InflooButton v-if="!isOpen  &&( element?.posts?.length > 0 || element?.advertisements?.length > 0)" 
                    @click.prevent="isOpen = true"
                    :className="'button arrow pl8 pr8'"
                >
                     <i class="lni lni-chevron-down"></i>
                </InflooButton>
                <InflooButton v-else-if="isOpen &&( element?.posts?.length > 0 || element?.advertisements?.length > 0)" 
                    @click.prevent="isOpen = false" 
                    :className="'button arrow pl8 pr8'"
                >
                     <i class="lni lni-chevron-up"></i>
                </InflooButton>    
            </div>
            <LayoutPostAndAdverts
                v-if="isOpen"
                :hasPosts="element.posts && element.posts.length > 0"
                :hasAdverts="element.advertisements && element.advertisements.length > 0"
            >
                <template v-slot:posts>
                    <ul   class="layout-list nomargin">
                        
                        <FacebookPostAnalytics 
                            v-for="post in element.posts" 
                            :key=post.postId 
                            :post="post"
                        >
                            {{post.message}}
                        </FacebookPostAnalytics>
                    </ul>
                </template>
                <template v-slot:adverts>
                    <ul  class="layout-list nomargin striped">
                        <FacebookAdvertAnalyticsElement 
                            v-for="advert in element.advertisements" 
                            :key=advert.facebookAdId 
                            :advert="advert"
                            class="layout-list-item"
                            :editing="true"
                            :open="true"
                        >
                        </FacebookAdvertAnalyticsElement>
                    </ul>
                </template>
            </LayoutPostAndAdverts>
    </li>
</template>
<script>
import {ref} from 'vue'
import {useCapabilities} from '@/utils/Capabilities'
import FacebookAdvertAnalyticsElement from '@/components/campaigns/analytics/facebook/FacebookAdvertAnalyticsElement'
import FacebookPostAnalytics from '@/components/campaigns/analytics/facebook/FacebookPostAnalytics'
import LayoutPostAndAdverts from '@/components/campaigns/analytics/facebook/LayoutPostAndAdverts'
import InflooButton from '@/components/buttons/InflooButton'
import {formatNumber} from '@/utils/NumberFunctions'
export default {
    props:{
        element:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item'
        },
    },
    components:{
        FacebookPostAnalytics,
        FacebookAdvertAnalyticsElement,
        LayoutPostAndAdverts,
        InflooButton
    },
    setup(props){
    
        const isOpen = ref(true)
        const publisher = ref(props.element.social.publisher)
        const {isAdvertiser} = useCapabilities()
        return{
            isOpen,
            publisher,
            formatNumber,
            isAdvertiser
        }
    }
}
</script>