<template>
    <div class="flex-container mb16">
        <div class="flex-container flex-col">
            <AjaxSearchSelect
                :config="{
                    mode:'tags',
                    trackBy:'name',
                    label:'name',
                    valueProp:'name',
                }"
                :editing="isAdmin() || isAdvertiser() ? editing :false"
                :feedUrl="ApiEndPoints.TAGS"
                @select="onTagSelect"
                :key="'ajax-search-'+ keysuffix"
                :selected="data.tags"
                @deselect="onTagDeSelect"
                label="target associati alla campagna "
            >
            </AjaxSearchSelect>
            <div class="flex-container mt8">
                <ul class="flex-container flex-col nomargin nopadding layout-list pr8 mr8">
                    <AvailableSocialElement 
                        v-for="social in data.available" 
                        :key="social.socialId+'-ase-' + keysuffix" 
                        :social="social"
                        :editing="editing"
                        @request-campaign="onRequestToSocial"
                    ></AvailableSocialElement>
                </ul>
                <ul class="flex-container flex-col nomargin nopadding layout-list pl8 ml8">
                    <CampaignSocialElement 
                        v-for="social in data.socialCampaigns" 
                        :key="social.socialId+'-cse-'+ keysuffix" 
                        :social="social"
                        :mode="'linked'"
                        @remove-social-from-campaign="withdrawRequestToSocial"
                        :editing="editing"
                        @authorize-social="authorizeSocialPressed"
                        @deauthorize-social="deAuthorizeSocialPressed"
                        @accept-social="acceptSocialPressed"
                        @unaccept-social="unacceptSocialPressed"
                    ></CampaignSocialElement>
                </ul>
            </div>
        </div>
       
    </div>
    
    
</template>
<script>
import { reactive,onMounted } from 'vue'
import {useStore} from 'vuex'
import {ApiEndPoints} from '@/api/Endpoints'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import CampaignSocialElement from '@/components/campaigns/elements/CampaignSocialElement'
import AvailableSocialElement from '@/components/campaigns/elements/AvailableSocialElement'
import{useCapabilities}from '@/utils/Capabilities'
export default {
    emits:[
        'data-changed',
        'authorize-social',
        'deauthorize-social',
        'accept-social',
        'unaccept-social'
    ],
    components:{
        CampaignSocialElement,
        AvailableSocialElement,
        AjaxSearchSelect
    },
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        editing:{
            type:Boolean,
            default:false
        },
        keysuffix:{
            type:Number,
            default:0
        },
    },
    setup( props, context ){
        const store = useStore()
        const data = reactive({
            tags:props.campaign.campaignTags.map(t =>t.tag),
            socialCampaigns:props.campaign.socialCampaigns || [],
            available:null
        })

        const {isAdmin , isAdvertiser} = useCapabilities()

        const getAvailableSocials = async() => {            
            try {
                const bytag = await store.dispatch('social/bytag', {
                    tags:data.tags,
                    discriminator: props.campaign.type
                })
                const connected = data.socialCampaigns.map(c => c.socialId)
                return bytag.filter ? bytag.filter(social => !connected.includes(social.socialId)):[]
            } catch (error) {
                console.log(error)
            }
        }

        onMounted(async()=>{
            data.available = await getAvailableSocials()
        })

        const onTagSelect = async(tag) => {   
            if(data.tags.indexOf(tag) === -1){
                data.tags.push(tag)
                data.available = await getAvailableSocials()
                context.emit('data-changed', data)
            }
        }

        const onTagDeSelect= async(tag) => {

            const index = data.tags.indexOf(tag)
            data.tags.splice(index, 1)
            if(data.tags.length > 0){
                data.available = await getAvailableSocials()
            } else {
                data.available = []
            }
            context.emit('data-changed', data)
        }

        const onRequestToSocial = async(social)=>{
            social.accepted = 'Candidate'
            //social.accepted = 'Pending'
            const campaignSocialElementData = {
                socialId:social.socialId,
                campaignId:props.campaign.id,
                social:social
            }
            data.socialCampaigns.push(campaignSocialElementData)
            data.available = await getAvailableSocials()
           
            context.emit('data-changed', data)
        }
        const withdrawRequestToSocial = async(social)=>{
            
            const index = data.socialCampaigns.findIndex(s => s.socialId === social.socialId)
            
            if(index!==-1){
                data.socialCampaigns.splice(index, 1)
                data.available = await getAvailableSocials()
                context.emit('data-changed', data)
               
            }
        }
        const authorizeSocialPressed = (social) => {
           context.emit('authorize-social', social)
        }
        const deAuthorizeSocialPressed = (social) => {
            context.emit('deauthorize-social', social)
        }

        const acceptSocialPressed = (social) => {
           
            context.emit('accept-social', social)
        }
        const unacceptSocialPressed = (social) => {
            context.emit('unaccept-social', social)
        }
        
        return{
            ApiEndPoints,
            data,
            onTagSelect,
            onTagDeSelect,
            onRequestToSocial,
            withdrawRequestToSocial,
            authorizeSocialPressed,
            deAuthorizeSocialPressed,
            acceptSocialPressed,
            unacceptSocialPressed,
            isAdmin,
            isAdvertiser
        }
    }
}
</script>
<style lang="postcss">
    .multiselect.is-tags.is-disabled{
        & .multiselect-caret{
            display: none;
        }
    }
</style>