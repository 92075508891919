<template>
    <div class="flex-container flex-col analytics-totals p8 no-grow">
         <h2 class="bebas nomargin mb16">Totali campagna</h2>
         <text-input
            :label="'Costo'"
            :value="formatNumber(data.cost) + ' €'"
            :editing="false"
            :name="'clicksLink'"
            :className="'flex-container flex-col flex-start-align flex-start-justify pt16'"
            :labelClassName="'nomargin'"
        >
        </text-input>
        <InstagramInsight
            v-if="isAdmin()"
            :insight="data"
            :likeCount="data.likeCount"
            :advertisements="data.advertisements"
            :mediaType="campaign.mediaType"
        ></InstagramInsight>  
        <AdvertiserFacebookInsight
            v-else
            :insight="data"
        ></AdvertiserFacebookInsight>  
    </div>
</template>
<script>
import { reactive } from 'vue'
import { useCapabilities } from '@/utils/Capabilities'
import InstagramInsight from '@/components/campaigns/analytics/instagram/InstagramInsight'
import AdvertiserFacebookInsight from '@/components/campaigns/analytics/facebook/AdvertiserFacebookInsight'
import TextInput from '@/components/formelements/TextInput'
import {formatNumber} from '@/utils/NumberFunctions'
import { getInstagramPostsInsightTotals } from '@/utils/AnalyticsFunctions'
export default {
    props:{
        socials:{
            type:[Object, String],
            default:''
        },
        campaign:{
            type:Object,
            default:()=>{}
        },
    },
    components:{
        InstagramInsight,
        AdvertiserFacebookInsight,
        TextInput
    },
    setup(props){
        const {isAdmin} = useCapabilities()
       
        let data = reactive({
            cost:0,
            albumEngagement: 0,
            albumImpressions: 0,
            albumReach: 0,
            albumSaved: 0,
            albumVideoViews: 0, 
            engagement: 0,
            likeCount: 0,
            exits: 0,
            impressions: 0,
            organicCoverage: 0,
            reach: 0,
            replies: 0,
            saved: 0,
            sponsoredCoverage: 0,
            tapsBack: 0,
            tapsForward: 0,
            totalCoverage: 0,
            videoViews: 0,
            advertisements: [],
        })
        

        const updateData = (insightObject) =>{
            
            Object.keys(insightObject).forEach(key =>{
                
                if(key !== 'advertisements'){
                    data[key] += insightObject[key] 
                } else {
                   
                    data.advertisements.push(...insightObject[key])
                }

                
                
            })
            
           
        }
        
        props.socials.forEach(social => {
            
            const { posts } = social
            if(posts){
                const postsInsights = getInstagramPostsInsightTotals(posts)
                
                updateData(postsInsights)
            }
            
        })
       
        
        return{
            data,
            isAdmin,
            formatNumber
        }
    }

    
}
</script>
<style lang="postcss">
    .analytics-totals{
        
        & label h3 {
            font-size: .9em;
        }
    }
</style>