<template>
     <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel pb24">
                <form 
                    class="flex-container flex-col min-height-inherit" 
                    id="add-post-panel" 
                    @submit.prevent="onNextPress"
                >
                    <InstagramAddMediaHeader />
                    
                    <InstagramAddPostPanelTitle
                        :socialName="'Modifica ' + state.postId "
                        :postId="state.postId || null"
                    ></InstagramAddPostPanelTitle> 

                    <div               
                        class="nomargin p24 flex-container flex-col  scrollable-content"
                    >
                        <p>
                            Inserisci i dati della story
                        </p>
                        <select-input
                            :label="'Media type'"
                            :value="state.mediaType"
                            :editing="true"
                            :options="['Image', 'Video']"
                            :name="'mediaType'"
                            :className="'flex-container flex-col mb8 w100 pr8'"
                            :required="true"
                            @change="e => state.mediaType = e.target.value"
                        ></select-input>
                        <TextInput
                            :label="'caption'"
                            :value="state.caption"
                            :editing="true"
                            :placeholder="'inserisci caption'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.caption = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'copertura organica'"
                            :value="state.organicCoverage"
                            :editing="true"
                            :placeholder="'inserisci copertura organica'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.organicCoverage = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'impressions'"
                            :value="state.impressions"
                            :editing="true"
                            :placeholder="'inserisci impressions'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.impressions = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'replies'"
                            :value="state.replies"
                            :editing="true"
                            :placeholder="'inserisci replies'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.replies = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'taps Back'"
                            :value="state.tapsBack"
                            :editing="true"
                            :placeholder="'inserisci tapsBack'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.tapsBack = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'like count'"
                            :value="state.likeCount"
                            :editing="true"
                            :placeholder="'inserisci tapsBack'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.likeCount = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'Comments Count'"
                            :value="state.commentsCount"
                            :editing="true"
                            :placeholder="'inserisci tapsBack'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.commentsCount = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'taps Forward'"
                            :value="state.tapsForward"
                            :editing="true"
                            :placeholder="'inserisci replies'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.tapsForward = val.text"
                        ></TextInput>
                        <TextInput
                            :label="'exits'"
                            :value="state.exits"
                            :editing="true"
                            :placeholder="'inserisci exits'"
                            :className = "'flex-container flex-col no-grow add-description pb16'"
                            :required="true"
                            @input="(val)=> state.exits = val.text"
                        ></TextInput>
                        
                    </div>
                   
                    
                    <div class="flex-container panel-footer abs w100">
                        <div class="flex-container layout-list-footer">
                            <input 
                                type="reset" 
                                :value="'annulla'" 
                                class="w100 noborder nopadding bebas" 
                                @click.prevent="onBackPress"
                            >
                            <input 
                                type="submit" :value="'salva'" 
                                class="w100 noborder nopadding bebas" 
                            >
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </teleport>
</template>
<script>
import { reactive} from 'vue'
import {useStore} from 'vuex'
import InstagramAddMediaHeader from '@/components/campaigns/instagram/panels/elements/InstagramAddMediaHeader'
import InstagramAddPostPanelTitle from '@/components/campaigns/instagram/panels/elements/InstagramAddPostPanelTitle'
import TextInput from '@/components/formelements/TextInput'
import SelectInput from '@/components/formelements/SelectInput'


export default {
    emits:['close', 'add-success', 'oauth-error'],
    props:{
        selectedPost:{
            type:Object,
            default:null
        }
    },
    components:{
        SelectInput,
        TextInput,
        InstagramAddMediaHeader,
        InstagramAddPostPanelTitle,
    },
    setup(props, {emit}){
       
        const store = useStore()
        const state = reactive({
            postId:props.selectedPost.postId,
            mediaType:props.selectedPost.mediaType,
            caption: props.selectedPost.caption,
            commentsCount: props.selectedPost.commentsCount,
            likeCount: props.selectedPost.likeCount,
            organicCoverage: props.selectedPost.insight.organicCoverage,
            impressions: props.selectedPost.insight.impressions,
            reach: props.selectedPost.insight.reach,
            exits: props.selectedPost.insight.exits,
            replies: props.selectedPost.insight.replies,
            tapsForward: props.selectedPost.insight.tapsForward,
            tapsBack: props.selectedPost.insight.tapsBack
        })
       
        
        /**
         * Esegiuta al chiudersi del pannello
         * 
         * @return Void
         */
        const onBackPress = () => {
           emit('close')
        }
        /**
         * Esegiuta al submit
         * a seconda dello step corrente
         * cerca post, carica post aggiuntivi,
         * salva i dati, salva la data di pubblicazione
         * prevista per un post
         * 
         * @return Void
         */
        const onNextPress = async () => {

            await saveStory()
        }
        

        const saveStory = async () => {
            
            try {
                const post = await store.dispatch('post/instagramMockUpdate',{...state, postId:props.selectedPost.postId})
                await store.dispatch('messages/message', {
                    title: 'Story aggiornata',
                    message:'la story ' + post.postId + ' è stata aggiornata correttamente'
                })
                emit('close')
                emit('add-success', post)
                
            } catch (error) {
                console.log(error)

                if(
                    error.message === "OAuthException" 
                ){
                    emit('close')
                    emit('oauth-error')
                } else {
                    await store.dispatch('messages/message', {
                        title:"errore durante l'associazione ", 
                        message:error.message 
                    })
                    emit('close')
                }
                
            }
        }

        
        


        const onManualStoryChanged = (storyData) => {
            state.manualStoryData = storyData
            let allEdit = true

            for (const key in storyData) {
                if(storyData[key] === null || storyData[key] === ''){
                    allEdit = false
                }
            }
           
            
            if(allEdit) {
                state.nextButtonText = 'avanti'
                state.preventNext = false
            }
        } 
        return{
            state,
            onBackPress,
            onNextPress,
            onManualStoryChanged
        }
    }
    
}
</script>
<style lang="postcss" scoped>
#add-post-panel button{
    line-height: 16px;
    font-size: 12px;
}
#add-post-panel .add-social-list .layout-list {
    max-height: 100%;
    height: initial;
    overflow-y: auto;
}
.overlay-panel #add-post-panel  input[type="reset"]:focus {
  outline: none;
  background-color: black;
  color: #F2F2F2;
}
#add-post-panel img {
    max-width: 100%;
}

.layout-list-footer{
        font-size: 22px;
    line-height: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.scrollable-content{
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
a.post-link {
    color: white;
    background-color: #2cbeff;
    text-align: center;
    padding: 1em 0;
    text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content{
    flex:unset;
}
.calendar-container{
    height: 240px;
}
.calendar-container .vuecal .vuecal__cell--disabled  {
    pointer-events:none;
}

</style>