<template>
    <ResizableColumnContainer 
        :maxCol="2" 
        @total-size="onTotalSize" 
    >
        <resizable-column 
            :columnIndex="0" 
            @column-resize="campaignColumnResize"
            ref="list"
            :style="listStyle"
        >
            <CampaignsList 
                :campaigns="campaigns" 
                :selectedId="route.params.id ? route.params.id : ''"
            ></CampaignsList>  
        </resizable-column> 
        <resizable-column 
            :columnIndex="1" 
            @column-resize="campaignColumnResize"
            ref="card"
            :style="cardStyle"
            :resizable="false"
        >
            <Tabs 
                v-if="campaign"
                :campaign="campaign" 
                :key="campaign.id"
                :selectedTab="selectedTab"
            ></Tabs>     
            <ColumnPlaceHolder v-else></ColumnPlaceHolder>  
        </resizable-column> 
    </ResizableColumnContainer>
    
    
</template>
<script>
import { useStore } from 'vuex'
import { useRoute , onBeforeRouteLeave, onBeforeRouteUpdate} from 'vue-router'
import {computed, onMounted, ref} from 'vue'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import Tabs from '@/components/campaigns/Tabs'
import CampaignsList from '@/components/campaigns/CampaignsList'
import ColumnPlaceHolder from '@/components/ColumnPlaceHolder'
import {apiCampaignFunction} from '@/api/campaigns'

import {
    list,
    listStyle,
    card,
    cardStyle,
    onTotalSize,
    campaignColumnResize

} from '@/utils/ColumnsResizer'
export default {
    components:{
        ResizableColumn,
        ResizableColumnContainer,
        CampaignsList,
        ColumnPlaceHolder,
        Tabs
    },
    setup(){
        const store = useStore()
        const route = useRoute()
        
        const campaigns = computed(() => {
          return store.getters['campaigns/filtered'] 
                ? store.getters['campaigns/filtered'] 
                : store.getters['campaigns/paged']
        })

        const tags = computed(() => {
          return store.getters['tags/tags']
        })
        
        const socials = computed(() => {
          return store.getters['social/social']
        })

        const campaign = ref(null)
        const selectedTab = ref('campaign')
       

        onMounted(async() => {
            
            if(route.params?.id){
                selectedTab.value = route.query?.tab ? route.query.tab : 'campaign'
                campaign.value = await apiCampaignFunction.show(route.params.id)
            } else {
                campaign.value = null
            }
        })
        
        onBeforeRouteLeave(async (to)=>{
            
            const cardWidth = card?.value?.$el ? card.value.$el.getBoundingClientRect().width +'px' : '70%'
            
            if(list?.value?.$el){
                store.commit('campaigns/savecolumnsizes',{
                    listW:list.value.$el.getBoundingClientRect().width+'px',
                    cardW:cardWidth,
                })
            }
            
            
            if (to.params.id ) {
                campaign.value = await apiCampaignFunction.show(to.params.id)
            }else {
                campaign.value = null
            }
        })

        onBeforeRouteUpdate(async (to, from) => {
            if (to.params.id !== from.params.id) {
                selectedTab.value = to.query?.tab
                campaign.value = await apiCampaignFunction.show(to.params.id)
                
            }else if (!to.params.id){
                campaign.value = null
            }
            

        })

        return {
            campaigns,
            campaign,
            campaignColumnResize,
            list,
            listStyle,
            card,
            cardStyle,
            onTotalSize,
            tags,
            socials,
            route,
            selectedTab
        }
    }
}
</script>
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style: none;
        
    }
    li {
        display: flex;
        min-width: 300px;
    }
    span {
        padding: .5em;
    }

</style>
