<template>
    <li class="flex-container flex-col">
        <div :class="className + ' advert-element lh24'">
        
            <div class="layout-list-item-title nomargin flex-container p8">
                    {{advert.name}}
            </div>
            <InflooButton
                v-if="editing" 
                @click.prevent="()=>$emit('edit-advert',advert)"
                :className="'button primary'"
            >
                modifica <i class="lni lni-pencil-alt"></i>
            </InflooButton>
            <InflooButton 
                v-if="editing"
                @click.prevent="()=>$emit('remove-advert',advert)"
                :className="'button secondary'"
            >
                rimuovi <i class="lni lni-close"></i>
            </InflooButton>
            <InflooButton v-if="editing &&!isOpen && data.advert?.ads && data.advert.ads.length >0 " 

                @click.prevent="isOpen = true"
                :className="'button arrow pl8 pr8'"
            >
                vedi <i class="lni lni-chevron-down"></i>
            </InflooButton>
            <InflooButton v-else-if="editing && isOpen && data.advert?.ads && data.advert.ads.length >0 " 
                @click.prevent="isOpen = false" 
                :className="'button arrow pl8 pr8'"
            >
                chiudi  <i class="lni lni-chevron-up"></i>
            </InflooButton>
            
            
        </div>
        <ul v-if="isOpen && data.advert?.ads && data.advert.ads.length >0 " class="pl24 mb8 mt8">
            <PostElement 
                v-for="ad in data.advert.ads" 
                :key="ad.postId" 
                :post="ad"
                :editing="editing"
                :removable="false"
                :className="'layout-list-item lh24'"
            ></PostElement>
        </ul>
    </li>
    
</template>
<script>
import {ref,reactive} from 'vue'
import InflooButton from '@/components/buttons/InflooButton'
import PostElement from '@/components/campaigns/elements/PostElement'
export default {
    emits:['remove-advert','edit-advert'],
    props:{
        advert:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container'
        },
        editing:{
            type:Boolean,
            default:false
        },
        open:{
            type:Boolean,
            default:false
        }
    },
    components:{
        InflooButton,
        PostElement
    },
    setup(props){

        const isOpen = ref(props.open)
        const data = reactive({
            advert:props.advert
        })
        

        return{
            isOpen,
            data
        }
    }
}
</script>