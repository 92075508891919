<template>
     <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel pb24">
                <form 
                    class="flex-container flex-col min-height-inherit" 
                    id="add-post-panel" 
                    @submit.prevent="onSubmit"
                >
                    <div 
                        class="flex-container flex-col no-grow"
                    >
                        <div 
                            class="panel-header p24"
                        >
                            <div 
                                class="mini-logo"
                            ></div>
                            
                            <h2 
                                class="panel-title nomargin bebas"
                            >
                                Associa Nuova Inserzione
                                <i class="lni lni-instagram-original"></i>
                            </h2>
                        </div>
                    </div>
                    <InstagramAddPostPanelTitle
                        :socialName="social.name"
                        :step="4"
                        :postId="post.id || null"
                    ></InstagramAddPostPanelTitle> 
                       
                    
                    <div 
                         
                        class="nomargin p24 flex-container flex-col  scrollable-content"
                    >
                        <p>
                            Ci sono Inserzioni da associare a questo post ? <br>
                            inserisci gli <span class="bold">id</span> delle campagne da associare, separati da una virgola
                        </p>
                        <TextAreaInput
                            :label="'Id Campagne'"
                            :value="''"
                            :editing="true"
                            :placeholder="'inserisci gli id delle campagne separati da una virgola'"
                            ref="description"
                            :className = "'flex-container flex-col add-description pb16'"
                            :required="false"
                            @input="onCampaignIdsInput"
                        ></TextAreaInput>
                        
                    </div>
                    <div class="flex-container panel-footer abs w100">
                        <div class="flex-container layout-list-footer">
                            <input 
                                type="reset" 
                                :disabled="isLoadingPosts" 
                                value="annulla" 
                                class="w100 noborder nopadding bebas" 
                                @click.prevent="onClose"
                            >
                            <input 
                                type="submit" :value="'associa'" 
                                class="w100 noborder nopadding bebas" 
                            >
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </teleport>
</template>
<script>
import {reactive} from 'vue'
import {useStore} from 'vuex'
import InstagramAddPostPanelTitle from '@/components/campaigns/instagram/panels/elements/InstagramAddPostPanelTitle'
import TextAreaInput from '@/components/formelements/TextAreaInput'

export default {
    emits:['close', 'add-success', 'oauth-error'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        social:{
            type:Object,
            default:()=>{}
        },
        post:{
            type:Object,
            default:()=>{}
        }
        
    },
    components:{
       
        TextAreaInput,
        InstagramAddPostPanelTitle
    },
    setup(props, {emit}){
       
        const store = useStore()
        
        const data = reactive({
            selectedSocial:props.social,
            selectedPost:props.post,
            campaignId:props.campaign.id,
            advertismentIds: []
        })
        /**
         * Esegiuta al chiudersi del pannello
         * 
         * @return Void
         */
        const onClose = () => {
            emit('close')
        }
        /**
         * Esegiuta al submit
         * a seconda dello step corrente
         * cerca post, carica post aggiuntivi,
         * salva i dati, salva la data di pubblicazione
         * prevista per un post
         * 
         * @return Void
         */
        const onSubmit = async () => {
            
            try {
                
                
                const adsLength = data.advertismentIds.length
                if(adsLength > 0){
                    for (let index = 0; index < adsLength; index++) {
                         
                        const adId =  data.advertismentIds[index] 
                        
                        const adPayload = {
                            socialId: data.selectedSocial.socialId,
                            campaignId: data.campaignId,
                            postId:data.selectedPost.postId,
                            advertisementId:adId
                        }
                       
                        // chiamata x sincare gli adv : POST - /api/advertisement/instagram
                        await store.dispatch('post/addInstagramAdvertismentToPost',adPayload)
                    }
                   
                } 

                await store.dispatch('messages/message', {
                    title: 'Inserzione associata',
                    message:"l'inserzione è stata associata correttamente"
                })

                emit('close')
                emit('add-success') 
                
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante l'associazione ", 
                    message:error.message 
                })
                emit('close')
            }
           
           
        }
        

        
        

        

        const onCampaignIdsInput = (e) => {
            const {text} = e
            if(text || text === ''){
                data.advertismentIds = text.split(',').map(t => t.trim())
            }
        }
        return{
            data,
            onClose,
            onSubmit,
            onCampaignIdsInput
        }
    }
    
}
</script>
<style lang="postcss" scoped>
#add-post-panel button{
    line-height: 16px;
    font-size: 12px;
}
#add-post-panel .add-social-list .layout-list {
    max-height: 100%;
    height: initial;
    overflow-y: auto;
}
.overlay-panel #add-post-panel  input[type="reset"]:focus {
  outline: none;
  background-color: #2cbeff;
  color: #F2F2F2;
}
#add-post-panel img {
    max-width: 100%;
}

.layout-list-footer{
        font-size: 22px;
    line-height: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.scrollable-content{
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
a.post-link {
    color: white;
    background-color: #2cbeff;
    text-align: center;
    padding: 1em 0;
    text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content{
    flex:unset;
}
.calendar-container{
    height: 240px;
}
.calendar-container .vuecal .vuecal__cell--disabled  {
    pointer-events:none;
}

</style>