<template>
    <li :class="className + ' accordion-social-element mb16 p16'">
        
            <div class="flex-container flex-space-between-justyfy w100 mb8">
                <h3 class="bebas pr16 nomargin pl8">{{element.social.name}}</h3>
                <p 
                    v-if="publisher"
                    class="pl8 layout-list-item-subtitle nomargin f12"
                >
                    <span><i>{{publisher.name}}</i></span>
                    <span class="pl8 black">{{publisher.concessionaire.name}}</span>
                </p>
                <div
                    v-if="editing" 
                    class="flex-container flex-end-justify"
                >
                    <InflooButton v-if="!showPrices && isAdmin()" 
                        @click.prevent="showPrices = true"
                    >
                        prezzi <i class="lni lni-euro"></i>
                    </InflooButton> 
                    <InflooButton v-else-if="showPrices && isAdmin()" 
                        @click.prevent="showPrices = false" 
                        :className="'button secondary pl8 pr8'"
                    >
                        prezzi <i class="lni lni-euro"></i>
                    </InflooButton>
                    
                    <InflooButton 
                        @click="$emit('insert-post',element)"
                    >
                        media <i class="lni lni-plus"></i>
                    </InflooButton>
                    
                    
                    <InflooButton v-if="!isOpen &&( element?.posts?.length > 0 || element?.advertisements?.length > 0 || publishDates.length >0) " 
                        @click.prevent="isOpen = true"
                        :className="'button arrow pl8 pr8'"
                    >
                        vedi <i class="lni lni-chevron-down"></i>
                    </InflooButton>
                    <InflooButton v-else-if="isOpen &&( element?.posts?.length > 0 || element?.advertisements?.length > 0 || publishDates.length >0)" 
                        @click.prevent="isOpen = false" 
                        :className="'button arrow pl8 pr8'"
                    >
                        chiudi  <i class="lni lni-chevron-up"></i>
                    </InflooButton>
                </div>        
            </div>
            <FacebookPagePrices
                v-if=showPrices
                :social="element.social"
                :editing="editing"
            ></FacebookPagePrices>
        
            <LayoutInstagramPagePosts 
                v-if="isOpen"
                :hasPosts="element.posts && element.posts.length > 0"
                :hasPublishDates="publishDates.length >0"
            >
                <template v-slot:posts>
                    <ul  v-if="element.posts && element.posts.length > 0" class="layout-list nomargin">
                        <InstagramPostElement 
                            v-for="post in element.posts" 
                            :key=post.postId 
                            :post="post"
                            :removable="true"
                            :editing="editing"
                            :className="'layout-list-item flex-container'"
                            @remove-post="()=>$emit('remove-post',post)"
                            @remove-advert="onRemoveAdvert"
                            @insert-advert="()=>$emit('insert-advert',element.social, post)"
                            @mock-edit="(post) =>$emit('mock-edit', post)"
                        >
                        </InstagramPostElement>
                    </ul>
                    <p class="nomargin f12 pl8" v-else>
                        nessun post attualmente associato
                    </p>
                </template>
                
                <template v-slot:publishdates>
                    <ul class="layout-list nomargin striped">
                       <FacebookTobePublishedElement
                        v-for="(data , index) in publishDates"
                        :key="'date'+index"
                        :data="data"
                        :editing="editing"
                        :calendarID="Math.round(Math.random()*1000000000).toString()"
                        @assign-publish-date="(pd)=>$emit('assign-publish-date', {...pd, social:element.social})"
                        @remove-publish-date="(pd)=>$emit('remove-publish-date', {...pd, social:element.social})"
                       >
                       </FacebookTobePublishedElement>
                    </ul>
                    
                </template>
            </LayoutInstagramPagePosts>
    </li>
</template>
<script>
import { ref, reactive } from 'vue'
import InstagramPostElement from '@/components/campaigns/instagram/posts/InstagramPostElement'
import FacebookPagePrices from '@/components/campaigns/facebook/posts/FacebookPagePrices'
import FacebookTobePublishedElement from '@/components/campaigns/facebook/posts/FacebookTobePublishedElement'
import LayoutInstagramPagePosts from '@/components/campaigns/instagram/posts/LayoutInstagramPagePosts'
import InflooButton from '@/components/buttons/InflooButton'
import {useUserFunctions} from '@/utils/UserFunctions'
import {useCapabilities} from '@/utils/Capabilities'
export default {
    emits:['insert-post', 'remove-post', 'insert-advert','remove-advert', 'edit-advert', 'assign-publish-date', 'mock-edit'],
    props:{
        element:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item'
        },
        editing:{
            type:Boolean,
            default:false
        },
        open:{
           type:Boolean,
            default:true
        }
    },
    components:{
        InflooButton,
        InstagramPostElement,
        FacebookPagePrices,
        LayoutInstagramPagePosts,
        FacebookTobePublishedElement
    },
    setup( props, context){
        
        
        const isOpen = ref(props.open)
        const showPrices= ref(false)
        const {isAdmin} = useCapabilities()
        const {userScopes} = useUserFunctions()
        const publisher = userScopes.value.find(s => s.id === props.element.social.publisherId)   
        const publishDates = reactive(props.element.events || [])
        const onRemoveAdvert = (adv) => {
            context.emit('remove-advert',adv)
        }
        
        return{
           isOpen,
           showPrices,
           publisher,
           publishDates,
           isAdmin,
           onRemoveAdvert
        }
    }
}
</script>
<style lang="postcss">
    #buttons{
        width: 150px;
        flex-wrap: wrap;
        justify-content: flex-end;
        & .confirm{
                min-width: 20ch;
        }
        & .deny{
                min-width: 20ch;
        }
    }
    .accordion-social-element{
        background-color: white;
    }
   
</style>