<template>
    <form :class="'campaign-edit layout-content w100  h100 header-content-footer-grid rel' " 
        @submit.prevent 
        ref="form"
        id="campaign-social-post-form"
    >
        <div class="layout-content-header flex-container p24 no-grow">
            <h1 class="nomargin bebas">{{campaign.title ? campaign.title : 'nome campagna'}}</h1>
        </div>
        <div 
            v-if="loading"
            class="flex-container pl24 pr24 flex-center-align flex-center-justify"
        >
            <p>loading</p>
        </div>
        <div 
            v-else
            class="flex-container flex-col scrollable-content pl24 pr24 card-content"
        >
           <InstagramPage 
            v-for="element in data" 
            :key="element.social.socialId + '-' + keyCount" 
            :element="element"
            @insert-post="insertPost"
            @remove-post="removePost"
            @assign-publish-date="onAssignPublishDate"
            @remove-publish-date="onRemovePublishDate"
            @insert-advert="onInsertAdvert"
            @remove-advert="onRemoveInstagramAdvert"
            @mock-edit="onMockEdit"
            :editing="editing"
        >
           </InstagramPage>
        </div>
        <form-footer
            @edit-enable="editing = true"
            @edit-disable="editing = false"
            @submit-form="creating =true"
            :buttons="{disableBtn:true,disableBtnMessage:'annulla',submitBtn:false,deleteBtn:false}"
            :editing="editing"
            :className= "'layout-content-footer abs w100 panel-footer'"
        ></form-footer>
        
    </form>
    <InstagramAddMedia
        v-if="creating"
        @close="creating = false"
        @add-success="onPostAdded"
        :campaign="campaign"
        :startStep="creatingStartStep"
        :calendarId="creatingCalendarId"
        :eventId="creatingCalendarEventId"
        :social ="selectedSocial.social"
        @oauth-error="resettingToken = true"
    ></InstagramAddMedia>
    <instagramAddAdvertisement
        v-if="creatingAdv"
        :post="selectedPost"
        :campaign="campaign"
        :social ="selectedSocial"
        @close="creatingAdv = false"
        @add-success="onPostAdded"
    ></instagramAddAdvertisement>
    <FacebookResetToken
        v-if="resettingToken"
        @close="resettingToken = false"
    ></FacebookResetToken>
    <InstagramEditMock
        v-if="editingMock"
        :selectedPost="selectedPost"
        @close="editingMock = false"
        @add-success="onPostAdded"
    >
    </InstagramEditMock>
</template>
<script>
import {computed, ref ,  onMounted} from 'vue'
import {useStore} from 'vuex'
import InstagramPage from '@/components/campaigns/instagram/InstagramPage.vue'
import InstagramAddMedia from '@/components/campaigns/instagram/panels/InstagramAddMedia'
import InstagramEditMock from '@/components/campaigns/instagram/panels/InstagramEditMock'
import instagramAddAdvertisement from '@/components/campaigns/instagram/panels/instagramAddAdvertisement'
import FacebookResetToken from '@/components/FacebookResetToken'
import FormFooter from '@/components/formelements/FormFooter'
import {useCapabilities} from '@/utils/Capabilities'
export default {
    emits :['posts-changed'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
       InstagramPage,
       InstagramEditMock,
       InstagramAddMedia,
       instagramAddAdvertisement,
       FormFooter,
       FacebookResetToken
    },
    setup(props){
        
        const store = useStore()
        const editing= ref(false)
        const loading= ref(true)
        const creating= ref(false)
        const editingMock= ref(false)
        const creatingStartStep = ref(0)
        const creatingAdv= ref(false)
        const creatingCalendarId= ref(null)
        const creatingCalendarEventId= ref(null)
        const {isAdmin} = useCapabilities()
        const addingAdvPost= ref(false)
        const selectedSocial = ref(null)
        const selectedPost = ref(null)
        const resettingToken = ref(false)
        const keyCount = ref(0)
        const  data = computed(()=>{
            return store.getters['campaigns/facebookPages']
        }) 


        const loadData = async()=>{
            loading.value = true
            try {
                await store.dispatch('campaigns/getApprovedSocialsForCampaign',
                    props.campaign.id
                )
            } catch (error) {
                console.log(error.message)
            }   
            loading.value = false
        }

        onMounted(async()=>{
           await loadData()
        }) 

        const onPostAdded = async()=>{
            await loadData() 
            keyCount.value++  
        }
        
        const onPostRemoved = async()=>{
            await loadData()
            keyCount.value++
        }
        const insertPost = ( social, step=0, calendarId = null, eventId =null ) => {
            selectedSocial.value = social
            creatingStartStep.value = step
            if(creatingStartStep.value === 0 && !isAdmin()){
                creatingStartStep.value = 1
            } else if(isAdmin()){
                creatingCalendarId.value= calendarId
                creatingCalendarEventId.value = eventId
            }
            creating.value = true
        }

        const onInsertAdvert = async (social, post) => {
            
            selectedSocial.value = social
            selectedPost.value = post
            creatingAdv.value = true
        }
        
        const removePost =async (post)=>{ 
            try {
                await store.dispatch('post/removeInstagramPost', post.postId)
                onPostRemoved()
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante la cancellazione del post" , 
                    message:error.message 
                })
            } 
        }

        
        const onAssignPublishDate = (pd) => {
            const { social, calendarID, data } = pd
            creatingStartStep.value = 1
            selectedSocial.value = {social:social}
            creatingCalendarId.value= calendarID
            creatingCalendarEventId.value = data.id
            creating.value = true 
        }

        const onRemovePublishDate = async(pd) =>{
            
            try {
                const {extendedProperties} = pd.data
                const {shared} = extendedProperties
                if(shared?.eventType === "shouldPublishPost"|| shared?.eventType === "postPublished"){
                    await store.dispatch('campaigns/deleteEvent',{
                        id: pd.data.id,
                        socialId:shared.socialId,
                        campaignId:shared.campaignId
                    })
                }
                await store.dispatch('messages/message', {
                        title: 'evento Eliminato',
                        message:'evento eliminato correttamente'
                })
                await onPostRemoved()
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante l'eliminazione ", 
                    message:error.message 
                }) 
            }
                
        }


        const onRemoveInstagramAdvert = async (adv) => {
            
            try {
                await store.dispatch('post/removeInstagramAdvertismentFromPost', adv.id)
                onPostRemoved()
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante la cancellazione dell'inserzione" , 
                    message:error.message 
                })
            } 
        }

        const onMockEdit = (post) => {
            selectedPost.value = post
            editingMock.value = true
        }
        
        return {
            data,
            selectedSocial,
            selectedPost,
            editing,
            creating,
            editingMock,
            creatingStartStep,
            creatingCalendarId,
            creatingCalendarEventId,
            creatingAdv,
            addingAdvPost,
            onPostAdded,
            onPostRemoved,
            insertPost,
            removePost,
            onInsertAdvert,
            keyCount,
            resettingToken,
            onAssignPublishDate,
            onRemovePublishDate,
            onRemoveInstagramAdvert,
            loading,
            onMockEdit
        }
    }
}
</script>
<style lang="postcss">
    .header-content-footer-grid{
        height:  calc(100vh - 9.15em);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2fr 0.125fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content"
            "footer";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
        }

        & .card-footer { grid-area: footer; }
    }
</style>
