<template>
    <form :class="'campaign-edit layout-content w100  h100 header-content-grid' " 
        @submit.prevent 
        ref="form"
        id="campaign-social-post-form"
    >
        <div class="layout-content-header flex-container p24 no-grow">
            <h1 class="nomargin bebas">{{campaign.title ? campaign.title : 'nome campagna'}}</h1>
        </div>
        <div 
            v-if="loading"
            class="flex-container pl24 pr24 flex-center-align flex-center-justify"
        >
            <p>loading</p>
        </div>
        <div
            v-else-if="campaign.type === 'Facebook'" 
            class="flex-container flex-col scrollable-content pl24 pr24 card-content">
            <FacebookPageTotals
                :key="'totals-'+ keyCounter"
                :socials="data"
                :campaign="campaign"
            >
            </FacebookPageTotals> 
           <facebook-page-analytics 
                v-for="element in data" 
                :key="element.social.socialId" 
                :element="element"
                :campaign="campaign"
            >
            </facebook-page-analytics>
        </div>
        <div
            v-else-if="campaign.type === 'Instagram'" 
            class="flex-container flex-col scrollable-content pl24 pr24 card-content">
            <InstagramPageTotals
                :key="'totals-'+ keyCounter"
                :socials="data"
                :campaign="campaign"
            >
            </InstagramPageTotals> 
           <InstagramPageAnalytics 
                v-for="element in data" 
                :key="element.social.socialId" 
                :element="element"
                :campaign="campaign"
            >
            </InstagramPageAnalytics>
        </div>
    </form>
</template>
<script>
import {onMounted, computed, ref, provide} from 'vue'
import {useStore} from 'vuex'
import FacebookPageAnalytics from '@/components/campaigns/analytics/facebook/FacebookPageAnalytics'
import FacebookPageTotals from '@/components/campaigns/analytics/facebook/FacebookPageTotals'
import InstagramPageAnalytics from '@/components/campaigns/analytics/instagram/InstagramPageAnalytics'
import InstagramPageTotals from '@/components/campaigns/analytics/instagram/InstagramPageTotals'
export default {
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        FacebookPageAnalytics,
        FacebookPageTotals,
        InstagramPageAnalytics,
        InstagramPageTotals
    },
    setup(props){
        const store = useStore()
        const  data = computed(()=>{
            return store.getters['campaigns/analyticsPages']
        })
        const keyCounter = ref(0)
        const loading = ref(true)
        
        const loadData = async()=>{
            loading.value = true
            try {
                await store.dispatch('campaigns/getAnalyticsForCampaign',
                    props.campaign.id
                )
                keyCounter.value++
            } catch (error) {
                console.log(error.message)
            }
            loading.value = false
            
        }

        onMounted(async()=>{
            //await store.dispatch('userscope/userScopes')
            await loadData()
        }) 


        provide('campaign', props.campaign)
        return{
            data,
            keyCounter,
            loading
        }
    }
}
</script>
<style lang="postcss" scoped>
    .header-content-grid{
        height:  calc(100vh - 9.15em);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2.8fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
        }

       
    }
</style>