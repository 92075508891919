import {store} from '@/store/store'
import {GET_USER_SCOPES} from '@/store/VuexPaths'
import {computed} from 'vue'



const selectedUserRole = computed(()=>{
    return store.getters['user/currentRole']
})
const selectedUser = computed(()=>{
    return store.getters['user/selected']
})
/* const editing = computed(()=>{
    return store.getters['user/editing']
}) */
const userScopes = computed(() => {
    return  store.getters[GET_USER_SCOPES]
})


export const getScopesForUser = (user) => {
    return user.userScope 
        ?   store.getters['userscope/scopes']
                .filter(s => s.discriminator === user.userScope.discriminator) 
        : null
}
/* export const availableScopes = computed(()=>{
    return user.userScope 
        ?   store.getters['userscope/scopes']
                .filter(s => s.discriminator === user.userScope.discriminator) 
        : null
}) */


export const useUserFunctions =() =>{
    return {
        selectedUserRole,
        selectedUser,
        //editing,
        userScopes
    }
}