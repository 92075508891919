<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            
            <div class="overlay-panel rel">
                <div class="flex-container flex-col p24">
                    <div class="panel-header ">
                        <div class="mini-logo"></div>
                         <h2 class="panel-title nomargin bebas">Aggiornamento Insights</h2>
                    </div>
                    <p>Aggiornamento Insights, in corso, l'operazione potrebbe richiedere qualche tempo... </p>
                </div>  
            </div> 
         </div>
      
    </teleport>
</template>
<script>
export default {
    props: {
    title: String,
    message:String
  }
}
</script>
<style lang="postcss">

.overlay-panel button.deny {
    color: white;
    background-color: black;
}
</style>