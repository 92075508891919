<template>
    <li :class="className + ' ig-post-element pl8 mb16 flex-col'">
        <div class="flex-container flex-space-between-justyfy no-grow pb8 mt8">
            <p class="bebas nomargin">
                <span>{{post.mediaProductType.toLowerCase() === 'story' || post.mock ? 'story' : 'post'}}</span>
                <span v-if="post.advertisements && post.advertisements.length > 0 "> con inserzioni</span>
            </p>
        </div>
        <div class="flex-container w100">
            <RetryingImageSocial
                :url="post.mediaUrl"
                :id="post.postId"
                :fallbackImage="true"
            />
            <div class="flex-container flex-col w60">
                <p class="layout-list-item-subtitle f12 nomargin pl8 lh24"><i>{{post.postId || 'no id from db :S'}}</i></p>
                <div class="layout-list-item-title nomargin pl8 lh24 pr8">
                    {{post.caption}} 
                </div>
            </div>
             <InflooButton
                v-if="editing"
                @click="()=>$emit('insert-advert',post)"
                :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
            >
                inserzioni <i class="lni lni-plus"></i>
            </InflooButton> 

            <InflooButton
                v-if="post.mock"
                @click="() =>$emit('mock-edit', post)"
                :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
            >
                modifica
            </InflooButton> 
            
            <InflooButton
                v-else
                @click="() =>openLocation(post.permalink)"
                :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
            >
                vedi
            </InflooButton> 
            <InflooButton 
                v-if="removable && editing"
                :className="'remove-post-from-campaign nomargin button primary flex-container flex-center-align flex-center-justify'"
                @click.prevent="()=>removePost(post)"
            >
                rimuovi <i class="lni lni-close"></i>
            </InflooButton>
        </div>
        <div
            v-if="post.advertisements && post.advertisements.length > 0 "
            class="w100 pt8 advertisements"
        >
            <div class="flex-container flex-center-align flex-space-between-justyfy no-grow">
                <p class="bebas nomargin">inserzioni</p>
            </div>
            <ul class="flex-container flex-col nomargin nopadding layout-list ">
                <li 
                    v-for="adv in post.advertisements" 
                    :key="adv.id" 
                    :class="'layout-list-item flex-container post-analytics-element flex-col'"
                >
                    <a 
                        @click="()=>$emit('list-select', post)"
                        class=" flex-container"
                    >
                        
                        <div class="flex-container flex-col">
                            <p class="layout-list-item-subtitle f12 nomargin lh24 pb8"><i>{{adv.id || 'no id from db :S'}}</i></p>
                            <div class="layout-list-item-title nomargin  lh24 ">
                                    {{adv.label}} 
                            </div>
                        </div> 
                        <InflooButton 
                            v-if="editing"
                            :className="'remove-post-from-campaign nomargin button primary flex-container flex-center-align flex-center-justify'"
                            @click.prevent="()=>removeAdvert(adv)"
                        >
                            rimuovi <i class="lni lni-close"></i>
                        </InflooButton>
                    </a>
                </li>
            </ul>
       </div>
        
    </li>
</template>
<script>
import { inject, computed} from 'vue'
import {useStore} from 'vuex'
import {openLocation} from '@/utils/CampaignFunctions'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
} from '@/utils/RolesAndPolicies'
import InflooButton from '@/components/buttons/InflooButton'
import RetryingImageSocial from '@/components/RetryingImageSocial'
export default {
    emits:['confirm-post', 'remove-post', 'list-select', 'remove-advert', 'mock-edit'],
    props:{
        post:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container flex-center-align'
        },
        editing:{
            type:Boolean,
            default:false
        },
        removable:{
            type:Boolean,
            default:false
        }
    },
    components:{
        InflooButton,
        RetryingImageSocial
    },
    setup(props, context){
        
        const me = inject('me')
        const store = useStore()
        const socialName = computed(()=>{
            return store.getters['social/social'].find(s => s.socialId === props.post.socialId)?.name || ''
        })
        const removePost = (post)=>{
            context.emit('remove-post', post)
        }
        const removeAdvert = (adv)=>{
            
            context.emit('remove-advert', adv)
        }
        
        return{
            me,
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            socialName,
            removePost,
            removeAdvert,
            openLocation
        }
    }
}
</script>
<style scoped>
.layout-content .layout-list-item a {
    
    font-weight: normal;
}
.layout-content input[type="reset"] {
    height: 40px;
}
.h40px{
    height: 40px;
}
.add-post-to-campaign{
    text-decoration: none;
    color: #ffffff;
    background-color: #2cbeff;
    text-align: center;
    line-height: 21px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    border: none;
    max-width: 75px;
}
.remove-post-from-campaign{
    background-color: #2f3a3f;
    color: #F2F2F2;
    text-decoration: none;
    text-align: center;
    line-height: 21px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    border: none;
    max-width: 75px;
}
.remove-post-from-campaign:hover,.pending a.remove-post-from-campaign:hover{
    background-color: #2cbeff;
    color:#000000;
}
.remove-post-from-campaign:disabled {
    opacity: 0;
}
.remove-post-from-campaign:focus {
    outline: none;
}
.ig-post-element{
    border-top: solid 1px #2cbeff
}
.ig-post-element.layout-list-item{
    border-bottom: none;
}
.ig-post-element img {
    height: 36px;
    width: 36px;
    object-fit: cover;

}
.primary:hover {
    background-color: #2f3a3f;
    color: #F2F2F2;
}
.advertisements{
    margin-bottom: 1em;
   /*  padding: 1em; */
    
    /* padding-right: 0;
    padding-left: 0; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

</style>